import { useStorage } from '@vueuse/core'
import dayjs from 'dayjs'
import { attSearchController } from '~/apis/back/services'
import type { RankListAttrDto } from '~/apis/back/types'

type AttrStorageDto = RankListAttrDto

export function useSearchAttr() {
  const searchAttrState = useState('AZGO_SEARCH_ATTR_STATE', () => {
    return {
      searchInput: '',
      confirmInput: '',
      searchAttrList: [] as Array<RankListAttrDto & { highlightName: string }>,
      isSearching: false,
      confirmAttrList: [] as RankListAttrDto[],
      searchLoading: false,
    }
  })
  const { searchInput, searchAttrList, isSearching, confirmAttrList, confirmInput, searchLoading } = toRefs(searchAttrState.value)

  const route = useRoute()
  const filterConditionStore = useFilterConditionsStore()
  const userInfoStore = useUserStore()

  const history = useStorage<AttrStorageDto[]>('AZGO_SEARCH_ATTR_HISTORY', [])
  function updateHistory(item: AttrStorageDto) {
    history.value = history.value.filter(historyItem => (
      historyItem.attrName !== item.attrName
    ))

    if (history.value.length >= 5) {
      history.value.pop()
    }
    history.value.unshift(item)
  }

  async function doSearch(keyWord: string) {
    searchLoading.value = true
    const searhResult = await attSearchController.attAttSearchPost({
      distCode: route.query.campaign_id as string || 'azgo',
      keyWord,
      cityId: filterConditionStore.filterConditions.selectedCity?.destinationId?.toString() || '',
      tagIdList: filterConditionStore.filterConditions.listTagList,
      currency: userInfoStore.userInfo.currency,
      searchDate: dayjs(filterConditionStore.filterConditions.date).format('YYYY-MM-DD'),
    })
    searchLoading.value = false
    return searhResult.data.value?.data || []
  }

  function clearSearch() {
    confirmAttrList.value = []
    confirmInput.value = ''
    searchInput.value = ''
    searchAttrList.value = []
    isSearching.value = false
    searchLoading.value = false
  }

  function watchSearchInput() {
    watch(
      searchInput,
      debounce(async (value) => {
        if (!value) {
          searchAttrList.value = []
          isSearching.value = false
          return
        }
        if (value.length >= 1) {
          isSearching.value = true
          const searhResult = await doSearch(value)
          searchAttrList.value = searhResult.slice(0, 10).map((item) => {
            const highlightName = item.attrName?.replace(new RegExp(`(${value.split('').join('|')})`, 'gi'), match => `<span style="color:var(--tc-color-primary)">${match}</span>`) || ''
            return { ...item, highlightName }
          }) || []
        }
      }, 300),
    )
  }

  async function onConfirmAttr(item?: RankListAttrDto) {
    if (!item) {
      confirmAttrList.value = searchAttrList.value
      confirmInput.value = searchInput.value
      updateHistory({ attrName: searchInput.value })
      return
    }
    confirmAttrList.value = [item]
    confirmInput.value = item.attrName || ''
    updateHistory(item)
  }

  return {
    searchInput,
    searchAttrList,
    isSearching,
    confirmAttrList,
    confirmInput,
    searchLoading,
    onConfirmAttr,
    history,
    updateHistory,
    clearSearch,
    watchSearchInput,
  }
}
