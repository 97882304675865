/**
 * 自动生成的代码，请勿修改
 */
import { doRequest } from './utils';
import type { ResponseType } from './utils';
import type {
  AttSearchRequest,
  ResponseListRankListAttrDto,
} from '../types';

const baseUrl = '';

class AttSearchControllerService {
  /**
   * @summary 景区搜索
   * @fullUrl /att/attSearch
   */
  public attAttSearchPost(
    body: AttSearchRequest,
  ): ResponseType<ResponseListRankListAttrDto> {
    const requestUrl = baseUrl + '/att/attSearch';
    const res = doRequest<ResponseListRankListAttrDto>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
}

export const attSearchController = new AttSearchControllerService();
